* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#chakra-modal-reservation__modal {
  background: #fff !important;
  height: 600px !important;
}

.chakra-modal__close-btn {
  color: #818181 !important;
  width: 32px !important;
  height: 32px !important;
}

.modal_header {
  display: flex;
  margin-left: 40px;
  margin-top: 20px;
}

.modal__header__text {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: -8px;
}

.modal-text {
  color: #646464;
  font-weight: 500;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.modal__id {
  color: #646464;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-top: -20px;
}

.modal_contacts {
  display: flex;
  margin-top: 30px;
  margin-left: 40px;
}

.modal__phone {
  display: flex;
  flex-direction: column;
}

.modal-phone-text {
  color: #121212;
  font-weight: 700;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.modal-phone-num {
  color: #646464;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.modal__email {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modal__details {
  margin-top: 30px;
  margin-left: 40px;
}

.modal__details_header {
  color: #121212;
  font-weight: 700;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.modal-details-content {
  display: flex;
  margin-left: 7px;
}

.modal-details-first {
  display: flex;
  flex-direction: column;
}

.modal-date {
  color: #5c5c5c;
  font-weight: 700;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.modal-date-text {
  color: #646464;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding-right: 15px;
}

#chakra-modal-suspend__modal {
  background: #fff !important;
}

.suspend__reason {
  color: #121212;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.suspend__reason__input {
  width: 350px;
  height: 40px;
  border-radius: 8px;
  background-color: #fff;
  color: #121212;
  border: 1px solid #c4c4c4;
  padding-left: 15px;
  margin-left: 25px;
}

#send__action {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  background-color: #db4e2e;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

#chakra-modal-delete__modal {
  background-color: #fff !important;
}

.delete__text {
  color: #121212;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.delete__action {
  width: 70px;
  height: 40px;
  background-color: green !important;
  border-radius: 8px;
  color: #121212;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.delete_action {
  width: 70px;
  height: 40px;
  background-color: red !important;
  border-radius: 8px;
  color: #121212;
  font-weight: 400;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

#chakra-modal-add__modal {
  background-color: #fff !important;
  width: 100% !important;
  height: 88vh !important;
}

.ads__text {
  text-align: left;
  padding-left: 30px;
  color: #121212;
  font-weight: 600;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-left: 30px;
}

.ads__form {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.ads__form__text {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  margin-left: 30px;
  padding-left: 30px;
}

.dur {
  margin-bottom: 10px;
}

.ads__input {
  width: 450px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding-left: 30px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 10px auto;
  background-color: #fff;
}

.banner__upload {
  padding-top: 10px;
}

input::placeholder {
  color: #5c5c5c;
}

.duration {
  display: inline-block;
  margin: auto;
  position: relative;
}

.ads__input__date {
  width: 220px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding-left: 30px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 15px;
  background-color: #fff;
  padding-top: 5px;
}

.ads__input__date_i {
  width: 220px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding-left: 30px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
  background-color: #fff;
}

.duration__text {
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #5c5c5c;
  position: absolute;
  left: 20px;
  margin-top: -5px;
}

.duration__text_i {
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #5c5c5c;
  position: absolute;
  left: 54%;
  top: 0;
  margin-top: -5px;
}

.create__ad__button {
  margin: 20px auto;
  background: #db4e2e !important;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  height: 55px !important;
  width: 450px;
}
