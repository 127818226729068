* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.advert__container {
  width: 100%;
  /* height: 100vh; */
  background-color: #e6e6e6;
}

.advert__content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.advert__text {
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  padding-left: 30px;
}

.advert__create {
  display: flex;
  justify-content: flex-end;
}

.create__button {
  width: 143px;
  height: 42px;
  background: #f56630;
  border-radius: 8px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  margin-right: 30px;
}

.advert__page {
  margin-top: 10px;
}

.campaigns__text {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  padding-left: 30px;
}

.campaigns__all {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  color: red;
  font-family: "Poppins", sans-serif;
  padding-right: 30px;
  margin-top: -38px;
  cursor: pointer;
}

.ads__details {
  display: flex;
  justify-content: space-evenly;
  margin-left: 30px;
  margin-right: 30px;
  /* flex-wrap: wrap; */
}

.ads__content {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.advert__img {
  width: 422px !important;
  height: 262px !important;
  object-fit: contain !important;
}

.advert__img_i {
  width: 422px;
  height: 262px;
  object-fit: cover;
}

.advert__img__text {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
}

.all__campaigns {
  margin-top: 10px;
}

.livecampaign__container {
  width: 100%;
  /* height: 100vh; */
  /* background-color: white; */
}

.livecampaign__text {
  padding-top: 30px;
}

.banner__loading {
  font-weight: 400;
  font-size: 14px;
  color: red;
  font-family: "Poppins", sans-serif;
  padding-left: 54px;
  margin-top: -5px;
}

.advert__pagination {
  margin-top: 30px !important;
}
