* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.third_card {
  width: 311px;
  height: 171px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  background: #fff !important;
}

.userlist {
  margin-top: 25px;
  margin-left: 30px;
}

.userlist__text {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  text-align: left;
  padding-top: 20px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #888888;
  width: 25%;
  margin-left: 10px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent; /* Hide the indicator */
  margin-right: 10px; /* Space between tabs */
  color: #222222;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.tab.active {
  border-color: #db4e2e; /* Show the indicator for the active tab */
  font-weight: bold;
}

.tab.inactive {
  color: #888888;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.content {
  padding: 20px;
}

.userstable__container {
  width: 1280px;
  height: 781px;
  background: #fff;
  margin: 15px auto;
  border-radius: 8px;
}

.reservation__details {
  height: 500px !important;
}

.table_users {
  margin-top: 15px !important;
}

.userdropdown__content {
  width: 175px;
  height: 184px;
  border-radius: 5px;
  position: absolute;
  top: 38%;
  right: 88%;
  background: white !important;
  border: 1px solid #e6e6e6;
}

.activity__dropdown {
  height: 100px !important;
}

.users__text {
  padding-top: 10px !important;
  cursor: pointer;
}

.users__delete_text {
  padding-top: 10px !important;
  color: red !important;
  cursor: pointer;
}

.activivties__container {
  margin-top: 40px;
}

.arrow__back {
  display: flex;
  margin-top: 30px;
  margin-left: 40px;
}

.activities__text {
  color: #101928;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  margin-left: 5px;
  margin-top: 15px;
}

.activities__box {
  width: 95%;
  height: 112px;
  background-color: #fff;
  border-radius: 8px;
  margin: 20px auto;
}

.box__content {
  display: flex;
  justify-content: space-between;
}

.box__profile {
  display: flex;
  /* margin-top: 5px; */
  margin-left: 20px;
  width: 30%;
}

.box-profile {
  margin-top: 20px !important;
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.first__image {
  margin-top: 6px !important;
}

.box__name {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
  padding-top: 5px;
}

.box-name {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding-top: 15px;
}

.box-at {
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: -20px;
  text-align: left;
}

.box__account {
  display: flex;
  margin-top: 25px;
  width: 30%;
}

.box__created {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.box-account {
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.account__values {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.box-values {
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.box {
  margin-left: 5px;
}

.box_i {
  margin-left: -7px;
  margin-top: -5px;
}

.box__dots {
  margin-top: 25px;
  margin-right: 35px;
  position: relative;
}

.activity__indicator {
  margin-left: 30px;
  margin-top: 30px;
  width: 497px;
  height: 62px;
  border-radius: 28px;
  border: 1px;
  background-color: #f2f2f2;
  display: flex;
}

.tab_i {
  padding-top: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent; /* Hide the indicator */
  margin-right: 10px; /* Space between tabs */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #121212;
  padding-left: 10px;
  margin-left: 10px;
}

.tab_i.active {
  width: 234px;
  height: 46px;
  border-radius: 28px;
  background-color: #fff;
  color: red;
  margin-top: 7px;
  margin-left: 5px;
  text-align: center;
}

.tab_i.inactive {
  color: #121212;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.box__followers {
  display: flex;
  justify-content: space-evenly !important;
  margin: 30px auto;
  /* margin-left: 30px; */
}

.boxes {
  width: 400px;
  height: 110px;
  border-radius: 8px;
  background: #fff;
}

.box__text {
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  padding-top: 20px;
  margin-top: 20px;
  margin-left: 25px;
}

.box-span-text {
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
}

.activity__details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.activity__post {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.pane {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent; /* Hide the indicator */
  margin-right: 10px; /* Space between tabs */
  color: #222222;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.pane.active {
  border-color: #db4e2e; /* Show the indicator for the active tab */
  font-weight: bold;
}

.pane.inactive {
  color: #888888;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.activity__filter {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.activity__activities {
  margin: 25px auto;
  width: 95%;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.activity__first {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.activity__first__details {
  display: flex;
  flex-direction: column;
}

.activity__first__name {
  display: flex;
  margin-top: 15px;
  margin-left: 7px;
}

.activity__first__text {
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
}

.no__post {
  text-align: center !important;
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  font-size: 28px !important;
  padding-top: 10px;
}

.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #6e6e6e;
  margin-top: 19px;
  margin-left: 4px;
}

.brook {
  color: #6e6e6e;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 4px;
}

.hrs {
  color: #a7a7a7;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-left: 4px;
}

.activity__first__details__text {
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: -10px;
  margin-left: 10px;
  /* width: 50%; */
}

.activity__first__details {
  margin-top: 6px;
}

.activity__second {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.activity__second__view {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.views {
  padding-top: 15px;
}

.comments {
  padding-top: 15px;
}

.rate {
  padding-top: 9px;
}

.rate_i {
  margin-top: -17px;
}

.view__text {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
