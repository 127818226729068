* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.auth__container {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.auth__content {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  margin: 25px 0 10px 20px;
  flex-direction: column;
  background-color: #fff;
}

.signin-text {
  margin: 20px 0 10px 25px;
}

.signin-admin {
  text-align: left;
  color: #5c5c5c;
  font-size: 48px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.login__form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 20px 0 10px 25px;
  background-color: #fff;
}

.text__name {
  text-align: left;
  color: #414141;
  font-size: 22px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

#login__input {
  width: 619px;
  height: 68px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  margin-bottom: 15px;
  padding-left: 15px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  z-index: 1;
}

input::placeholder {
  color: #5c5c5c;
}

.password {
  position: relative !important;
}

.password__icon {
  position: absolute;
  top: 50.7%;
  left: 38%;
  color: #5c5c5c;
  z-index: 5;
}

.password__icon_i {
  position: absolute;
  top: 67.7%;
  left: 38%;
  color: #5c5c5c;
  z-index: 5;
}

.forgot-pass {
  margin-top: 4px;
  margin-right: 45px;
  cursor: pointer;
}

.forgot__text {
  text-align: right;
  color: red;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-right: 45px;
  padding-right: 65px;
}

#auth_button {
  width: 619px;
  height: 68px;
  border-radius: 8px;
  color: #fff;
  background-color: #db4e2e;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  border: 1px solid #fff;
}

.login_account {
  margin-top: 20px;
}

.login__account__text {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #414141;
}

.login__span {
  color: red;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.auth__image {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  height: 100vh;
}

.background-img {
  object-fit: cover;
  width: 864px !important;
}

.forgot__password__container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  background-color: #fff !important;
  height: 100vh;
}

.forgot__password {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: #000;
  margin-top: 40px;
}

.forgot__email {
  color: #fff !important;
}

.otp__text {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  color: #fff !important;
}

#reset__input {
  margin-left: 30px !important;
  width: 90% !important;
}

#reset__email {
  margin-left: 30px !important;
  width: 90% !important;
  margin-bottom: 15px !important;
}

#reset__button {
  margin-left: 30px !important;
  width: 90% !important;
}

#password__icon {
  margin-top: 25px;
  font-size: 30px;
}
