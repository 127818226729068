* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root[data-theme="dark"],
[data-theme][data-theme="dark"] {
  --chakra-colors-chakra-body-bg: #e6e6e6 !important;
  height: 100vh !important;
}

.maindashboard__container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: "sidebar main";
  height: 100vh !important;
  background-color: #e6e6e6;
}

.sidebar__container {
  grid-area: sidebar;
  background-color: #fff;
  height: 100% !important;
}

.sidebar__content {
  padding-left: 25px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar__logo {
  display: flex;
  margin-bottom: 20px;
  margin-top: -18px;
}

.logo__text {
  padding-left: 10px;
  color: #646464;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-top: 20px;
}

.sidebar__dashboard {
  display: flex;
  margin-bottom: 20px;
  margin-top: 25px;
}

.sidebar {
  display: flex;
  flex-direction: column;
}

.sidebar__dashboard {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.sidebar__dashboard {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.sidebar__dashboard.selected {
  background: #db4e2e; /* Change to the indicator color */
  border-radius: 10px;
}

.dashboard_icon,
.sidebar__text {
  color: #646464;
  padding-left: 12px; /* Default color */
}

.sidebar__dashboard.selected .dashboard_icon,
.sidebar__dashboard.selected .sidebar__text {
  color: white; /* Change to white when selected */
}

.sidebar__text {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding-top: 20px;
}

/*Header Styles*/

.header__container {
  position: static;
}

.header__content {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  position: relative;
}

.header__text {
  display: flex;
  justify-content: flex-start;
}

.header__context {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.header__text {
  margin-left: 15px;
}

.header-text {
  padding-left: 10px;
  font-size: 28px;
  font-weight: 700px;
  color: #646464;
  font-family: "Poppins", sans-serif;
}

.header__input {
  margin-right: 18px;
  display: flex;
  position: relative;
}

.header__search {
  width: 438px;
  height: 42px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding-left: 35px;
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 5px;
  background: #fff;
}

.search__icon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 12px;
  left: 10px;
  color: #000;
}

.header__icons {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  margin-top: -22px;
}

.notification_icon {
  margin-right: 7px;
  height: 60px !important;
  margin-top: 10px;
}

.message__icon {
  margin-right: 7px;
  height: 60px !important;
  margin-top: 10px;
}

.user__icon {
  margin-right: 7px;
  height: 60px !important;
  margin-top: 10px;
}

.header__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure the overlay appears above other elements */
}

.header__dropdown {
  position: absolute;
  top: 82px;
  right: 15px;
  width: 200px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  background-color: #fff;
}

.header__dropdown__icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.header__dropdown__icon_i {
  display: flex;
  margin-bottom: 15px;
  cursor: pointer;
}

.header__dropdown__icon__logout {
  color: red;
  height: 30px;
  width: 30px;
}

.header__dropdown__icon__text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding-left: 10px;
}

.set {
  padding-top: 5px;
  font-size: 16px !important;
}

/* Cards Styles */

.cards__container {
  padding-top: 20px;
}

.cards__holder {
  display: flex;
  justify-content: space-evenly;
}

.first_card {
  width: 245px;
  height: 171px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  background: #fff !important;
}

.second_card {
  width: 422px;
  height: 171px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  background: #fff !important;
}

.wallet__content {
  display: flex;
  margin-top: 30px;
}

.wallet__income {
  display: flex;
  flex-direction: column;
}

.wallet__icon {
  margin-left: 20px;
  margin-top: 15px;
}

.wallet__income {
  margin-left: 15px;
}

.wallet-text {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 5px;
  font-weight: 400;
}

.wallet-figure {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  padding-top: 0px;
  font-weight: 500;
  margin-top: -14px;
}
.wallet__increase {
  display: flex;
  margin-top: -17px;
  padding-top: 10px;
  margin-left: 30px;
}

.up-text {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 5px;
  font-weight: 400;
  padding-left: 8px;
}

/* Analytics Styles */

.analytics-container {
  display: flex;
  justify-content: space-evenly;
  margin-right: 15px;
}

.analytics__container {
  margin-top: 15px;
  margin-left: 10px;
  width: 772px;
  height: 397px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  margin-bottom: 15px;
}

.analytics__content {
  display: flex;
  justify-content: space-between;
}

.analytics_content {
  margin-left: 10px;
}

.analytics_content {
  display: flex;
  justify-content: flex-start;
}

.analytics__keys {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.analytics-blue {
  display: flex;
  margin-top: 5px;
  margin-left: 7px;
  margin-right: 7px;
}

.blue__icon {
  height: 11px !important;
  margin-top: 18px;
}

.caret__icon {
  height: 20px !important;
  margin-top: 15px;
}

.analytics__text {
  padding-top: 10px;
  color: #5c5c5c;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.blue__key {
  padding-left: 3px;
  color: #818181;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  padding-top: 16px;
}

.traffic__container {
  width: 510px;
  height: 397px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-top: 15px;
  margin-left: 10px;
}

.traffic__content {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.traffic__reserv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.traffic-calender {
  /* width: 87px;
  height: 29px;
  border-radius: 8px;
  background-color: #eaeaea;
  border: 1px solid #e6e6e6; */
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.traffic__start {
  padding-left: 3px;
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding-top: 7px;
  margin-left: -9px;
}

.traffic_text {
  padding-left: 3px;
  color: #5c5c5c;
  font-size: 32px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  padding-top: 3px;
  margin-top: -17px;
}

.traffic-txt {
  padding-left: 3px;
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  padding-top: 3px;
}

.calender-icon {
  width: 14px;
  height: 14px;
  margin-top: 5px;
  margin-right: 10px;
}

.month__text {
  color: #646464;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding-top: 3px;
  margin-right: 7px;
}

.recharts-bar-rectangle {
  height: 40px !important;
}

.recharts-layer {
  height: 40px !important;
}

.reservation__table {
  background: #fff;
  width: 1300px;
  height: 586px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.reservation__content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.reservation__text {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.reservation__activity {
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #000;
  padding-left: 15px;
}

.reservation__calendar {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  width: 85px;
  height: 37px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding-top: 8px;
}

.calendar__text {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-right: 8px;
}

.reservation-caret {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.table__container {
  border-collapse: collapse;
  width: 1258px;
  border: 1px solid #dddddd;
  border-radius: 12px !important;
  margin: 30px auto;
  background-color: #fff;
}

th {
  border: 1px solid #f6f6f6;
  text-align: center;
  padding: 8px;
  height: 74px;
  background-color: #f6f6f6;
}

td {
  border-bottom: 1px solid #dddddd;
  text-align: center;
  margin-left: 10px;
}

.table__header__text {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.table__header__text_i {
  display: none;
}

.table__status {
  width: 104px;
  height: 24px;
  border-radius: 10px;
  background: #e7f6ec;
  color: #0f973d;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 5px;
}

.reservatn-status {
  width: 104px;
  height: 24px;
  border-radius: 10px;
  background: #fbeae9;
  color: #d42620;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 5px;
}

.reserv_status {
  width: 104px;
  height: 24px;
  border-radius: 10px;
  background: #ffece5;
  color: #ad3307;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 5px;
}

.reservation_action {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e4e7ec;
  margin-left: 15px;
  margin-top: 17px;
}

.three-dots {
  color: #5c5c5c;
  height: 27px;
  width: 27px;
}

.reservation__table__rw {
  height: 60px;
}

.horizontal_line {
  width: 341%;
  margin-left: -18px;
  margin-top: 17px;
  color: #757575;
}

.reservation__table__first {
  padding-left: 18px;
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

#table__details {
  padding-top: 15px;
  /* margin-top: 10px; */
}

#reserv__status__bt {
  width: 140px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #38cb89;
  background-color: lightcyan;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;
}

#reserv__status__bt__pending {
  width: 140px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #c46f08;
  background-color: #fbedea;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;
}

#reserv__status__bt__reject {
  width: 140px;
  height: 40px;
  border-radius: 5px;
  background-color: lightpink;
  border: 1px solid #eb5151;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;
}

#reserv__status__bt__bill {
  width: 140px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;
}

.reservation__table__sec {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.view__all {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: red;
  cursor: pointer;
  margin-top: -16px;
}

a {
  text-decoration: none !important;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: -14px;
  cursor: pointer;
  border-color: #fff !important;
}

#page__item {
  list-style-type: none !important;
  border-color: #fff !important;
  list-style: none !important;
  margin-left: 5px;
}

.dropdown_container {
  margin-top: 12px;
  position: relative;
}

.dropdown__content {
  width: 175px;
  height: 70px;
  border-radius: 5px;
  position: absolute;
  top: 38%;
  right: 88%;
  background: white !important;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.dropdown__text {
  font-weight: 400;
  font-size: 14px;
  color: #646464;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding-top: 23px;
}

.all__container {
  /* margin: 20px 0 0 30px; */
  background-color: #e6e6e6 !important;
}

.all__arrow {
  margin: 10px 0 0 10px;
}

.all__content {
  display: flex;
  justify-content: space-between;
}

.all__text {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 0 15px;
}

.all__recent {
  font-weight: 500;
  font-size: 28px;
  color: #101928;
  font-family: "Poppins", sans-serif;
}

.all__fliter {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 30px;
  margin-top: 20px;
}

.filter-input {
  width: 320px;
  height: 42px;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  padding-left: 15px;
  border: 1px solid #c4c4c4;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-right: 15px;
  margin-top: 1px;
}

.filter__content {
  width: 87px;
  height: 36px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f56630;
  margin-right: 15px;
}

.filter__icon {
  margin-top: 8px;
  margin-left: 10px;
}

.filter-text {
  font-weight: 600;
  font-size: 14px;
  color: #f56630;
  font-family: "Poppins", sans-serif;
  margin-top: -19px;
  padding-left: 20px;
}

.export__content {
  width: 143px;
  height: 42px;
  background: #f56630;
  border-radius: 8px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.download__icon {
  margin-top: 9px;
  margin-left: 5px;
}

.export-text {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  margin-top: -19px;
  padding-left: 20px;
}

.all__reservation__container {
  margin: 15px 30px 10px 30px;
  width: 1306px;
  height: 800px;
  background: #fff;
  border-radius: 8px;
}

.all__recent__content {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.recent__reservation__text {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.recent__reservation__activity {
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #000;
  padding-left: 15px;
}

.recent__reservation__calendar {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  width: 85px;
  height: 37px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding-top: 8px;
}

.all__calendar__text {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-right: 8px;
}

.reservation-caret {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.select_filter {
  margin-top: 1px !important;
  height: 42px !important;
  margin-right: 15px !important;
  border: 1px solid #c4c4c4;
  text-align: center !important;
}

.reservation__table__container {
  background-color: #e6e6e6 !important;
}
