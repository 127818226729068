* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.second__analytics {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.sales__container {
  width: 771px;
  height: 375px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
}

.top__container {
  width: 515px;
  height: 375px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
}

.sales__content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sales-text {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 18px;
}

.sales_text {
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.sales__calendar {
  width: 88px;
  height: 29px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
  border: 1px solid #c4c4c4;
  margin-top: 10px;
  margin-right: 12px;
}

.sales-calendar {
  width: 42px;
  height: 17px;
  margin-top: 4px;
}

.sales-text_i {
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-right: 9px;
  padding-top: 3px;
}

.sales-caret {
  width: 14px;
  height: 14px;
  margin-top: 6px;
  margin-right: 7px;
}

.sales_slider_content {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.sales_content_i {
  display: flex;
  justify-content: flex-end;
}

.slider {
  width: 620px;
  margin-right: 25px;
  margin-top: 10px;
}

.rangeslider-horizontal {
  height: 6px !important;
  border-radius: 10px !important;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #db4e2e !important;
  border-radius: 10px !important;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  display: none !important;
}

.value {
  display: none !important;
}

.sales-week {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: -9px;
}

.sales__week {
  font-weight: 400;
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
}

.sales__amount {
  font-weight: 500;
  color: #646464;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 20px;
  margin-left: -15px;
  margin-right: 36px;
}

.top__content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.top__text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
}

.top__performing {
  font-weight: 500;
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.top__performing_i {
  font-weight: 500;
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-top: -13px;
  margin-left: -31px;
}

.top__restaurant {
  width: 124px;
  height: 29px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: 400;
  color: #818181;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.top__restaurant_text {
  font-weight: 400;
  color: #797979;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-right: 19px;
  padding-top: 3px;
}

.top__caret {
  width: 14px;
  height: 14px;
  margin-top: 8px;
  margin-right: 5px;
}

.restaurant__table {
  background: #fff;
  width: 1300px;
  height: 586px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
}

.table__container_i {
  border-collapse: collapse;
  width: 1258px;
  border: 1px solid #dddddd;
  border-radius: 12px !important;
  margin: 19px auto;
}

.view__all_rest {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: red;
  cursor: pointer;
  margin-top: 10px;
}

.all-rest {
  height: 1022px !important;
}

.top__text {
  color: red;
}

.doghnut__value {
  position: absolute;
  top: 46%;
  left: 79px;
}

.doughnut__text {
  font-weight: 400;
  font-size: 12px;
  color: #646464;
  font-family: "Poppins", sans-serif;
}

.doughnut__text_i {
  font-weight: 500;
  font-size: 22px;
  color: #5c5c5c;
  font-family: "Poppins", sans-serif;
  margin-top: -8px;
}
